exports.components = {
  "component---src-templates-404-index-js": () => import("./../../../src/templates/404/index.js" /* webpackChunkName: "component---src-templates-404-index-js" */),
  "component---src-templates-account-login-js": () => import("./../../../src/templates/account/login.js" /* webpackChunkName: "component---src-templates-account-login-js" */),
  "component---src-templates-account-profile-index-js": () => import("./../../../src/templates/account/profile/index.js" /* webpackChunkName: "component---src-templates-account-profile-index-js" */),
  "component---src-templates-account-profile-order-history-js": () => import("./../../../src/templates/account/profile/order-history.js" /* webpackChunkName: "component---src-templates-account-profile-order-history-js" */),
  "component---src-templates-account-profile-page-js": () => import("./../../../src/templates/account/profile/page.js" /* webpackChunkName: "component---src-templates-account-profile-page-js" */),
  "component---src-templates-account-profile-subscription-js": () => import("./../../../src/templates/account/profile/subscription.js" /* webpackChunkName: "component---src-templates-account-profile-subscription-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog/category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog/index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-collection-all-products-js": () => import("./../../../src/templates/collection/all-products.js" /* webpackChunkName: "component---src-templates-collection-all-products-js" */),
  "component---src-templates-collection-bundles-collection-js": () => import("./../../../src/templates/collection/bundles-collection.js" /* webpackChunkName: "component---src-templates-collection-bundles-collection-js" */),
  "component---src-templates-collection-index-js": () => import("./../../../src/templates/collection/index.js" /* webpackChunkName: "component---src-templates-collection-index-js" */),
  "component---src-templates-collection-manage-products-js": () => import("./../../../src/templates/collection/manage-products.js" /* webpackChunkName: "component---src-templates-collection-manage-products-js" */),
  "component---src-templates-collection-one-time-js": () => import("./../../../src/templates/collection/one-time.js" /* webpackChunkName: "component---src-templates-collection-one-time-js" */),
  "component---src-templates-discount-index-js": () => import("./../../../src/templates/discount/index.js" /* webpackChunkName: "component---src-templates-discount-index-js" */),
  "component---src-templates-gift-card-index-js": () => import("./../../../src/templates/gift-card/index.js" /* webpackChunkName: "component---src-templates-gift-card-index-js" */),
  "component---src-templates-lexicon-index-js": () => import("./../../../src/templates/lexicon/index.js" /* webpackChunkName: "component---src-templates-lexicon-index-js" */),
  "component---src-templates-lexicon-ingredient-js": () => import("./../../../src/templates/lexicon/ingredient.js" /* webpackChunkName: "component---src-templates-lexicon-ingredient-js" */),
  "component---src-templates-page-index-js": () => import("./../../../src/templates/page/index.js" /* webpackChunkName: "component---src-templates-page-index-js" */),
  "component---src-templates-preview-index-js": () => import("./../../../src/templates/preview/index.js" /* webpackChunkName: "component---src-templates-preview-index-js" */),
  "component---src-templates-product-index-js": () => import("./../../../src/templates/product/index.js" /* webpackChunkName: "component---src-templates-product-index-js" */),
  "component---src-templates-shopping-flow-choose-box-index-js": () => import("./../../../src/templates/shopping-flow/choose-box/index.js" /* webpackChunkName: "component---src-templates-shopping-flow-choose-box-index-js" */)
}

